import { createSlice } from "@reduxjs/toolkit";

export const userSlice = createSlice({
  name: "login",
  initialState: {
    userState: false,
    addUser: [],
    signUp: false, // if the user is already in the database
    signIn: false, // if the user is not registered
    updated: false,
  },
  reducers: {
    userCurrentState: (state, action) => {
      state.userState = action.payload.allow;
    },
    addUserData: (state, action) => {
      // replace the old array with the new array
      return { ...state, addUser: action.payload };
    },
    addSignUpState: (state, action) => {
      state.signUp = action.payload.error;
    },
    addSignInState: (state, action) => {
      state.signIn = action.payload.notRegistered;
    },
    // toggle operation, it negates the boolean value
    addUpdatedState: (state) => {
      return { ...state, updated: !state.updated };
    },
  },
});
export const {
  userCurrentState,
  addUserData,
  addSignUpState,
  addSignInState,
  addUpdatedState,
} = userSlice.actions;

export default userSlice.reducer;
