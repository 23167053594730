import { createSlice } from "@reduxjs/toolkit";

/************************************
 * exported to orders/ModalCardItem.js
 ***********************************/
export const ordersSlice = createSlice({
  name: "orders",
  initialState: {
    items: [],
    addItem: 0,
    itemsInCart: 0,
  },
  reducers: {
    // appends new data, without deleting the previews entry
    addItemsToCart: (state, action) => {
      return { ...state, items: [...state.items, ...action.payload] };
    },
    incrementItem: (state) => {
      return {
        ...state,
        addItem: state.addItem < 3 ? state.addItem + 1 : 3,
      };
    },
    decrementItem: (state) => {
      return {
        ...state,
        addItem: state.addItem > 1 ? state.addItem - 1 : 0,
      };
    },
    addItemInCart: (state) => {
      return {
        ...state,
        itemsInCart: state.itemsInCart + 1,
      };
    },
    // exported to CheckOut.js
    updateItemInCart: (state, action) => {
      return { ...state, itemsInCart: action.payload };
    },
    resetItemsToCarts: (state) => {
      state.items = [];
    },
    resetAddItem: (state) => {
      // reset totalItems variable to 0 in order/ModalCardItem.js
      state.addItem = 0;
    },
  },
});
export const {
  addItemsToCart,
  incrementItem,
  decrementItem,
  addItemInCart,
  resetAddItem,
  resetItemsToCarts,
  updateItemInCart
} = ordersSlice.actions;

export default ordersSlice.reducer;
