import React from "react";
import { useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router";

/******************************
 * RequestAuth
 * exported to App.js
 * https://github.com/remix-run/react-router/blob/dev/examples/auth/src/App.tsx
 *****************************/
export default function RequestAuth({ children }) {
  const user = useSelector((state) => state.login.userState);
  let location = useLocation();

  if (!user) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to={"/"} state={{ from: location }} replace />;
  }
  return children;
}
