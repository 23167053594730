import { configureStore } from "@reduxjs/toolkit";
import userSlice from "./userSlice";
import ordersSlice from "./orders";

/*****************************
 * store
 ****************************/
export default configureStore({
  reducer: {
    login: userSlice,
    orders: ordersSlice,
  },
});
